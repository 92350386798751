<template>
  <v-main>
    <v-snackbar :color="color" :value="snackbar" :timeout="3000" :multi-line="true">
      <b>{{ snackbarTxt }}</b>
    </v-snackbar>

    <router-view />

    <dashboard-core-footer />
  </v-main>
</template>

<script>

export default {
  name: 'DashboardCoreView',

  mounted: async function () {

    this.sockets.subscribe("WebClient", (event) => {
      event = JSON.parse(event)
      
      console.log('WebClient:', event.code)

      this.snackbar = false;
      this.color = event['code'] === 0 ? 'primary' : event['code'] === -1 ? 'warning' : 'error';
      this.snackbarTxt = event['message'];
      setTimeout(() => {
        this.snackbar = true;
      }, 0)
    });

  },

  components: {
    DashboardCoreFooter: () => import('./Footer'),
  },

  data: () => ({
    color: 'primary',
    snackbar: false,
    snackbarTxt: '',
  }),
}
</script>

<style scoped lang="sass">
.v-snackbar--material
    margin-top: 32px
    margin-bottom: 32px

    .v-alert--material,
    .v-snack__wrapper
        border-radius: 4px

    .v-snack__content
        overflow: visible
        padding: 0
</style>